<template>
	<div class="common-include">
		<div class="p-myheadnone"></div>
		<el-row :gutter="10">
			<el-col :span="5">
				<div class="p-box-card" shadow="hover">
					<div v-for="(item,index) in chooselist" @click="whichchoose(item.id)" :key="index" :class="['item',cindex==item.id?'item itemchoose':'']">
						<span class="p-choosespan">{{item.name}}</span><img v-show="cindex==item.id" class="p-imgleft" src="../../../assets/img/my/left.png" />
					</div>
				</div>
			</el-col>
			<el-col :span="19">
				<!-- 我的课程 -->
				<div v-if="cindex==1">
					<div label="全部课程" class="p-el-tab-pane">
						<div class="p-common-head">
							<div v-for="(item,index) in aboutclass" :key="index" :class="['p-common-head-item',whichabout==index ?'p-common-head-item p-common-head-item-c':'']"
							 @click="getmyclass(index,timetype,timename)" >
								{{item.name}}
							</div>
							<div class="p-my-rank" @click="showtime()">
								<img src="../../../assets/img/my/rank.png" />
								<div>{{timename}}</div><img class="p-rank-i-img" src="../../../assets/img/my/down.png" />
								<div class="p-m-rank-posi" v-if="showtimechoose==1">
									<div :class="['p-m-rank-item',timetype==2 ?'p-m-rank-item p-m-rank-item-c':'']" @click="getmyclass(whichabout,2,'按最近学习排序')">
										按最近学习排序
									</div>
									<div :class="['p-m-rank-item',timetype==1 ?'p-m-rank-item p-m-rank-item-c':'']" @click="getmyclass(whichabout,1,'按加入时间排序')">
										按加入时间排序
									</div>
								</div>
							</div>
						</div>
						<div :class="['p-collect-item',seewhich==index?'p-collect-item p-collect-item-choose':'']" v-for="(item,index) in collectlist"
						 :key="index" @click="seeclass(item.goods_id,index)" @mouseenter="seewhich=index">
							<img class="p-collect-item-img" :src="item.goods_img" />
							<div class="p-collect-item-right">
								<div class="p-collect-title">
									<div>{{item.goods_name}}</div>
									<div class="p-start-text"><img class="p-start-video" src="../../../assets/img/my/video.png" />开始学习：{{item.contents_name}}</div>
								</div>
								<div class="p-right">
									<div style="float: right;">
										<el-progress :width="50" :height="50" color="#00D0B0" type="circle" :percentage="item.calc"></el-progress>
										<div class="p-status">已学习</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-order-item-bottom">
						<span v-if="noneclass==0">没有课程了哟~</span>
						<span @click="getmoreclass()" v-if="noneclass==1">点击加载更多</span>
					</div>
				</div>
				<!-- 我的订单 -->
				<div v-if="cindex==2">
					<div class="p-common-head">
						<div v-for="(item,index) in ordertype" :key="index" :class="['p-common-head-item',orchoose==item.type ?'p-common-head-item p-common-head-item-c':'']"
						 @click="orderchoose(item.type)">
							{{item.name}}
						</div>
					</div>
					<div class="p-order-head">
						<div class="p-o-div1">订单详情</div>
						<div class="p-o-div2">单价(元)</div>
						<div class="p-o-div3">实付(元)</div>
						<div class="p-o-div4">交易状态</div>
						<div class="p-o-div5">操作</div>
					</div>
					<div v-for="(item,index) in order" :key="index" :class="['p-order-item',whichabout==index ?'p-order-item p-collect-item-choose':'']"
					 @click="whichabout=index" @mouseenter="whichabout=index">
						<div class="p-order-item-head">
							<img src="../../../assets/img/my/tclass.png" />
							<div>{{item.nickname}}</div>
							<div>{{item.addtime}}</div>
							<div>订单号：{{item.order_sn}}</div>
						</div>
						<div class="p-order-item-item" v-for="(items,index) in item.order_goods" :key="index">
							<div class="p-o-div1">
								<img :src="items.goods_img" />
								<div>
									{{items.goods_name}}
								</div>
							</div>
							<div class="p-o-div2 mr17px">
								￥{{items.goods_price}}
							</div>
							<div class="p-o-div3 mr17px ">
								￥{{item.total_money}}
							</div>
							<div class="p-o-div4 mr17px">
								<div v-if="item.status==2||item.status==1" style="color: #00D0B0;">交易成功</div>
								<div v-if="item.status==0" style="color: #F83850;">待付款</div>
								<div v-if="item.status==3">交易失败</div>
							</div>
							<div class="p-o-div5 mr17px">
								<div v-if="item.status==1" @click="gotocommit(item.id)">去评价</div>
								<div v-else-if="item.status==0" @click="gopay(item)">立即支付</div>
								<div v-else @click="delorder(item.id)">立即删除</div>
							</div>
						</div>
					</div>
					<div class="p-order-item-bottom">
						<span v-if="noneorder==0">没有订单了哟~</span>
						<span @click="getmoreorder()" v-if="noneorder==1">点击加载更多订单</span>
					</div>
				</div>

				<!-- 收藏夹 -->
				<div v-if="cindex==3">
					<div class="p-p-head" style="background-color: #FFFFFF;">
						<div class="p-collect-box-head">收藏夹（{{mycollect.total}}）</div>
					</div>
					<div :class="['p-collect-item',whichabout==index?'p-collect-item p-collect-item-choose':'']" v-for="(item,index) in mycollect.data"
					 :key="index" @click="whichaboutsee(index)" @mouseenter="whichabout=index">
						<img class="p-collect-item-img" :src="item.goods_img"  @click="whichpictrue(item.goods_id)"/>
						<div class="p-collect-item-right">
							<div class="p-collect-title">
								<div>{{item.goods_name}}</div>
								<div class="p-start-text" style="color: #F83850;">
									<!-- <img class="p-start-video" src="../../../assets/img/my/video.png" /> -->
									<span v-if="item.is_free==0">免费</span>
									<span v-if="item.is_free==1">{{item.goods_price}}元</span>
								</div>
							</div>
							<div class="p-right">
								<div style="float: right;">
									<div class="p-colloet-the" v-if="item.status==1" @click="cancelcollect(item.goods_id)">取消收藏</div>
									<div class="p-colloet-the" v-if="item.status==2">收藏</div>
								</div>
							</div>
						</div>
					</div>
					<div class="p-order-item-bottom">
						<span v-if="nonecollect==0">没有课程了哟~</span>
						<span @click="getmorecollect()" v-if="nonecollect==1">点击加载更多</span>
					</div>
				</div>
				<!-- 优惠券 -->
				<div v-if="cindex==4">
					<div class="p-common-head">
						<div v-for="(item,index) in couponlist" :key="index" :class="['p-common-head-item',cochoose==item.type ?'p-common-head-item p-common-head-item-c':'']"
						 @click="whichcoupon(item.type)">
							{{item.name}}({{item.use}})
						</div>
					</div>
					<div class="p-order-head">
						<div class="p-j-div1">优惠券</div>
						<div class="p-j-div2">适用范围</div>
						<div class="p-j-div3">有效期限</div>
						<div class="p-j-div4">操作</div>
					</div>
					<div v-for="(item,index) in coupon" :key="index" :class="['p-juan-item',whichabout==index ?'p-juan-item p-collect-item-choose':'']"
					 @click="whichabout=index" @mouseenter="whichabout=index">
						<div class="p-order-item-item">
							<div class="p-j-div1">
								<div v-if="cochoose==1" class="p-j-div1-backimg">
									<div class="p-juan-title">{{item.discount}}元</div>
									<div class="p-juan-about">{{item.coupon_name}}</div>
								</div>
								<div v-if="cochoose!=1" class="p-j-div1-backimga">
									<div class="p-juan-title">{{item.discount}}元</div>
									<div class="p-juan-about">{{item.coupon_name}}</div>
								</div>
							</div>
							<div class="p-j-div2 lh138">
								<div>仅限{{item.school_name}}课程使用</div>
							</div>
							<div class="p-j-div3 lh138">
								{{item.start_time}}至{{item.end_time}}
							</div>
							<div class="p-j-div4 lh138">
								<div v-if="cochoose==1" style="background: #00D0B0;" @click="tohome()">立即使用</div>
								<div v-if="cochoose==2" style="background: #00D0B0;opacity: 0.5;">已使用</div>
								<div v-if="cochoose==3" style="background: #CCCCCC;">已过期</div>
							</div>
						</div>
					</div>
					<div class="p-order-item-bottom">
						<span v-if="nonecoupon==0">没有数据了哟~</span>
						<span @click="getmorecoupon()" v-if="nonecoupon==1">点击加载更多数据</span>
					</div>
				</div>
				<!-- 设置 -->
				<el-card v-if="cindex==5">
					<div class="p-common-head">
						<div :class="['p-common-head-item',userchoose==1 ?'p-common-head-item p-common-head-item-c':'']" @click="userchoose=1">
							个人信息
						</div>
						<div :class="['p-common-head-item',userchoose==2 ?'p-common-head-item p-common-head-item-c':'']" @click="userchoose=2">
							账号设置
						</div>
					</div>
					<div v-if="userchoose==1">
						<div class="c-title"><span class="c-mrspan9">基本信息</span></div>
						<el-form ref="form" label-width="80px">
							<el-form-item label="头像">
								<el-upload action="123" :show-file-list="false" name="images" accept="image/*" :on-success="handleAvatarSuccess"
								 :before-upload="beforeAvatarUpload">
								 <el-image v-if="userinfo.avatarurl"  :src="userinfo.avatarurl" class="p-imageavatar"></el-image>
								 <i v-else class="el-icon-plus p-imageavatar"></i>
								</el-upload>
							</el-form-item>
							<el-form-item label="昵称">
								<el-input class="p-inputclass" maxlength="10" v-model="userinfo.nickname"></el-input>
							</el-form-item>
							<div class="c-title"><span class="c-mrspan9">联系方式</span></div>
							<el-form-item label="手机号码">
								<el-input class="p-inputclass" maxlength="11" v-model="userinfo.mobile"></el-input>
								<el-form-item>
									<img class="p-tips" src="../../../assets/img/my/spantips.png" />用于接收平台和机构的学习提醒等服务，不对外公开
								</el-form-item>
							</el-form-item>
							<el-form-item label="常用邮箱">
								<el-input class="p-inputclass" v-model="userinfo.email"></el-input>
								<el-form-item>
									<img class="p-tips" src="../../../assets/img/my/spantips.png" />用于接收平台和机构的邮件通知，务必认真填写
								</el-form-item>
							</el-form-item>
							<el-form-item label="QQ号码">
								<el-input class="p-inputclass" maxlength="13" v-model="userinfo.qq"></el-input>
								<el-form-item>
									<img class="p-tips" src="../../../assets/img/my/spantips.png" />用于课程的交流，便于学员与机构老师之间的沟通与交流
								</el-form-item>
							</el-form-item>
						</el-form>
						<div @click="edituser()" class="p-my-save">
							保存
						</div>
					</div>
					<div v-if="userchoose==2">
						<div class="c-title"><span class="c-mrspan9">当前登录的账号</span></div>
						<div class="p-text">{{username}}（{{whichlogin}}）</div>
						<div class="p-text">学习记录以当前登录账号为准</div>
						<el-divider></el-divider>
						<div class="c-title"><span class="c-mrspan9">手机账号</span></div>
						<div class="p-text">绑定手机号后可使用该手机号直接登录当前账号
							<el-input placeholder="请输入手机号" maxlength="11" class="p-inputclass" v-model="userinfo.mobile"></el-input>
							<el-input placeholder="输入验证码" maxlength="6" class="p-inputclassa" v-model="code"></el-input>
							<el-button v-if="phonechoe==1" class="ml17" @click="editmobile">
								绑定手机号
							</el-button>
							<el-button v-if="phonechoe==2" class="ml17" @click="getcode()">
								获取验证码
							</el-button>
						</div>
						<el-divider></el-divider>
						<div class="c-title"><span class="c-mrspan9">第三方账号</span></div>
						<div class="p-text">绑定后可使用以下账号直接登录当前账号</div>
					</div>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data() {
			return {
				//上传图片
				posts: "https://ykt.qdy2020.com/index/uploadimage",
				show:2,
				//我的课程
				timetype: 2,
				click: 0,
				classsize: 5,
				noneclass: 0,
				timename: "按最近学习排序",
				showtimechoose: 0,
				seewhich: 0,
				juandata: [{
					name: "仅限XXX机构课程使用",
					date: "2020-11-01至2020-11-11"
				}],
				aboutclass: [{
						name: "全部课程",
						id: 0
					},
					{
						name: "VIP课程",
						id: 1
					},
					{
						name: "免费课程",
						id: 2
					}
				],
				collectlist: [{
					title: '北京顶级名师双师课堂|名师开讲啦，免费公开课，想听到慢满满的干货吗？快来吧!',
					id: 1,
					image: "https://zhaosheng.qdy2020.com/images/Yet0UGfhW6PHqUmDOLYRewQaVmRLXsAAwHC706ZM.jpeg",
					precent: "10%",
					zt: "已完成"
				}, {
					title: '北京顶级名师双师课堂|名师开讲啦，免费公开课，想听到慢满满的干货吗？快来吧!',
					id: 1,
					image: "https://zhaosheng.qdy2020.com/images/Yet0UGfhW6PHqUmDOLYRewQaVmRLXsAAwHC706ZM.jpeg",
					precent: "10%",
					zt: "已完成"
				}, ],
				//订单
				cochoose: 1,
				orchoose: 1,
				order: [],
				ordersize: 5,
				noneorder: 0,
				ordertype: [{
						name: "全部订单",
						type: 1
					},
					{
						name: "待付款",
						type: 2
					},
					{
						name: "交易完成",
						type: 3
					},
					{
						name: "待评价",
						type: 5
					},
					{
						name: "交易失败",
						type: 4
					},
				],
				//优惠卷
				coupon: [],
				nonecoupon: 0,
				couponsize: 5,
				couponlist: [{
						name: "未使用",
						type: 1,
						use: '',
					},
					{
						name: "已使用",
						type: 2,
						use: '',
					},
					{
						name: "已过期",
						type: 3,
						use: '',
					}
				],
				//收藏
				nonecollect: 0,
				collectsize: 5,
				mycollect: {},
				//主体
				chooselist: [{
						name: "我的课程",
						id: 1
					},
					{
						name: "我的订单",
						id: 2
					},
					{
						name: "我的收藏",
						id: 3
					},
					{
						name: "优惠券",
						id: 4
					},
					{
						name: "设置",
						id: 5
					}
				],
				cindex: 3,
				collapse: false,
				whichabout: 1,
				//用户
				userinfo: {},
				userchoose: 1,
				username: "游客",
				whichlogin: "手机登录",
				phonechoe: 2,
				code: ""
			};
		},
		created() {
			this.cindex = this.$route.query.id
			if (localStorage.getItem("name")) {
				this.username = localStorage.getItem("name")
			}
			if (localStorage.getItem("whichlogin")) {
				this.whichlogin = localStorage.getItem("whichlogin")
			}
			this.whichchoose(this.$route.query.id)
		},
		watch: {
			'$route': function() {
				this.whichchoose(this.$route.query.id)
			},
		},
		methods: {
			//我的课程
			getmyclass(index, e, name) {
				this.whichabout = index
				this.timetype = e
				this.timename = name
				this.$get("goods/mygoods/", {
					type: index,
					timetype: this.timetype,
					size: this.classsize,
				}).then(response => {
					if (response.error == 0) {
						this.collectlist = response.data.data
						if (this.classsize >= response.data.total) {
							this.noneclass = 0
						} else {
							this.noneclass = 1
						}
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			getmoreclass() {
				this.classsize = this.classsize + 5
				this.getmyclass(this.whichabout, this.timetype, this.timename)
			},
			seeclass(e, index) {
				this.seewhich = index
				this.$router.push({
					path: "/courseDetail?id=" + e
				})
			},
			showtime() {
				this.click = this.click + 1
				if (this.click % 2) {
					this.showtimechoose = 1
				} else {
					this.showtimechoose = 0
				}

			},
			//头像上传
			handleAvatarSuccess(res, file) {},
			postpicture(file, which) {
				let param = new FormData(); // 创建form对象
				param.append('images', file); // 将文件存入file下面
				let config = {
					header: {
						'content-type': 'multipart/form-data'
					},
				}
				axios.post(this.posts, param, config)
					.then(res => {
						if (which == 1) {
							this.userinfo.avatarurl = res.data.data
						}
					}).catch(err => {
						console.log(err)

					})
			},
			beforeAvatarUpload(file) {
				var that = this
				this.postpicture(file, 1)
			},
			//选择目标项，降低网络访问次数
			whichchoose(e) {
				this.cindex = e
				if (e == 1) {
					this.getmyclass(0, 2, '按最近学习排序')
				}
				if (e == 2) {
					this.getorder(1)
				}
				if (e == 3) {
					this.getmycollect()
				}
				if (e == 4) {
					this.getcoupon(1)
				}
				if (e == 5) {
					this.getuser()
				}
			},
			//订单
			delorder(e) {
				this.$post("order/delorder/", {
					order_id: e,
				}).then(response => {
					if (response.error == 0) {
						this.$message.success(response.msg)
						this.getorder(this.orchoose)
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			gopay(data) {
				this.$router.push({
					path: '/payWay',
					query: {
						data: data,
					}
				})
			},
			getorder(e) {
				this.orchoose = e
				this.$get("user/myorders", {
					status: e,
					size: this.ordersize
				}).then(response => {
					if (response.error == 0) {
						this.order = response.data.orderList.data
						if (this.ordersize >= response.data.orderList.total) {
							this.noneorder = 0
						} else {
							this.noneorder = 1
						}
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			getmoreorder() {
				this.ordersize = this.ordersize + 5
				this.getorder(this.orchoose)
			},
			orderchoose(e) {
				this.orchoose = e
				this.getorder(e)
			},
			//用户信息
			getuser() {
				this.$get("user/getuserinfo", {}).then(response => {
					if (response.error == 0) {
						this.userinfo = response.data.userInfo
						localStorage.setItem("userinfo", response.data.userInfo)
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			getcode() {
				this.phonechoe = 1
				this.$get("code/sendverifycode", {
					phone: this.userinfo.mobile,
				}).then(response => {
					if (response.error == 0) {
						this.$message.success(response.msg)
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			editmobile() {
				this.$post("user/editmobile", {
					mobile: this.userinfo.mobile,
					code: this.code
				}).then(response => {
					if (response.error == 0) {
						this.$message.success(response.msg)
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			edituser() {
				this.$post("user/edituserinfo", {
					avatarurl: this.userinfo.avatarurl,
					nickname: this.userinfo.nickname,
					mobile: this.userinfo.mobile,
					email: this.userinfo.email,
					qq: this.userinfo.qq
				}).then(response => {
					if (response.error == 0) {
						this.$message.success(response.msg+",稍后生效")
						localStorage.setItem("name",this.userinfo.nickname)
						localStorage.setItem("img",this.userinfo.avatarurl)
						this.$router.push({
							path:"my",
							query:{
								id:5,
								name:this.userinfo.nickname+this.userinfo.avatarurl,
							}
						})
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},

			//优惠券
			getcoupon(e) {
				this.cochoose = e
				this.$get("user/mycoupon", {
					status: e
				}).then(response => {
					if (response.error == 0) {
						this.coupon = response.data.couponList.data
						this.couponlist[0].use = response.data.noUseCount
						this.couponlist[1].use = response.data.useCount
						this.couponlist[2].use = response.data.failCount
						if (this.couponsize >= response.data.couponList.total) {
							this.nonecoupon = 0
						} else {
							this.nonecoupon = 1
						}
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			getmorecoupon() {
				this.couponsize = this.couponsize + 5
				this.getcoupon(this.cochoose)
			},
			tohome() {
				this.$router.push({
					path: '/home',
					query: {
						id: 1
					}
				})
			},
			whichcoupon(e) {
				this.cochoose = e
				this.getcoupon(e)
			},
			gotocommit(e) {
				this.$router.push({
					path: '/commit',
					query: {
						id: e
					}
				})
			},
			//我的收藏
			cancelcollect(id) {
				this.$post("goods/collectgoods", {
					goods_id: id,
					status: 2
				}).then(response => {
					if (response.error == 0) {
						this.$message.success(response.msg)
						this.getmycollect()
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			whichaboutsee(e){
				this.whichabout = e
			},
			whichpictrue(e){
				this.$router.push({
					path: "/courseDetail?id=" + e
				})
			},
			getmycollect() {
				this.$get("user/mycollectlist", {}).then(response => {
					if (response.error == 0) {
						this.mycollect = response.data.collectList
						if (this.collectsize >= response.data.collectList.total) {
							this.nonecollect = 0
						} else {
							this.nonecollect = 1
						}
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			getmorecollect() {
				this.collectsize = this.collectsize + 5
				this.getmycollect()
			},
		},
	};
</script>

<style>
	.p-myheadnone {
		height: 40px;
		width: 100%;
	}

	.p-common-head {
		width: 100%;
		height: 70px;
		background: #FFFFFF;
		margin-bottom: 20px;
		display: flex;
		flex-wrap: wrap;
		line-height: 70px;
		align-items: flex-start;
	}

	.p-common-head-item {
		height: 98%;
		margin-left: 21px;
		font-size: 18px;
		cursor: pointer;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		margin-right: 32px;
	}

	.p-common-head-item-c {
		color: #00D0B0;
		border-bottom: 3px solid #00D0B0;
	}

	.p-box-card {
		width: 232px;
		height: auto;
		background: #FFFFFF;
		border: 1px solid #E6E6E6;
	}

	.p-imageavatar {
		width: 140px !important;
		height: 140px !important;
		border-radius: 8px;
		margin-top: 5px;
	}	

	.item {
		width: 230px;
		height: 70px;
		line-height: 70px;
		margin-bottom: 20px;
		font-size: 18px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
	}

	.itemchoose {
		background: #D9F8F3;
		color: #00D0B0;
		font-weight: bold;
		width: 232px !important;
	}

	.p-choosespan {
		margin-left: 20px;
	}

	.item :hover {
		background: #D9F8F3;
	}

	.p-inputclass {
		width: 198px;
		border-radius: 8px;
	}

	.p-inputclassa {
		width: 128px;
		border-radius: 8px;
	}

	.p-tips {
		width: 13px;
		height: 14px;
		margin-right: 4px;
		margin-top: 9px;
		float: left;
		margin-bottom: auto;
	}

	.p-text {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		margin-left: 14px;
		margin-bottom: 13px;
		color: #666666;
	}

	.ml17 {
		margin-left: 17px;
		background: #00D0B0;
		color: white;
		font-size: 12px;
		text-align: center;
		border-radius: 5px;
	}

	.p-imgleft {
		float: right;
		margin-right: 22px;
		width: 7px;
		height: 13px;
		margin-top: 29px;
	}

	/* 我的收藏样式 */
	.p-el-tabs-head {
		height: 70px;
		background: #FFFFFF;
		line-height: 70px;
	}

	.ml21 {
		padding-left: 21px;
	}

	.p-collect-item {
		width: 100%;
		height: 180px;
		margin-bottom: 20px;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		background: #FFFFFF;
	}

	.p-collect-item-choose {
		border: 1px solid #00D0B0;
	}

	.p-collect-item-img {
		width: 230px;
		height: 142px;
		border-radius: 10px;
		margin-top: auto;
		margin-bottom: auto;
		margin-left: 20px;
		cursor: pointer;
	}

	.p-collect-item-right {
		color: #333333;
		width: 70%;
		margin-left: 20px;
		margin-top: 33px;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.p-collect-title {
		width: 60%;
		line-height: 24px;
		font-size: 16px;
	}

	.p-right {
		width: 40%;
		height: 100%;
	}

	.p-start-text {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #7B7B7B;
		line-height: 36px;
	}

	.p-start-video {
		width: 17px;
		height: 13px;
		float: left;
		margin-right: 11px;
		margin-top: 11px;
	}

	.p-status {
		margin-top: 12px;
		text-align: center;
		font-size: 14px;
	}

	.p-colloet-the {
		width: auto;
		max-width: 80px !important;
		min-width: 64px !important;
		height: 24px;
		background: #F6F6F6;
		text-align: center;
		line-height: 24px;
		font-size: 12px !important;
		border-radius: 5px;
		margin-top: 46px;
		cursor: pointer;
	}

	.p-collect-box-head {
		margin-left: 22px;
	}

	.p-p-head {
		width: 100%;
		height: 46px;
		line-height: 46px;
		background: rgba(255, 255, 255, 0.95);
		border-bottom: 1px solid #E6E6E6;
	}

	/* 优惠券 */
	.p-juan-image {
		width: 114px;
		height: 60px;
	}

	.p-juan-item {
		width: 100%;
		height: 138px;
		background: #FFFFFF;
		margin-bottom: 2px;
	}

	.lh138 {
		line-height: 138px;
	}

	.p-j-div1 {
		width: 288px;
		height: 100%;
		margin-left: 60px;
	}

	.p-j-div1-backimg {
		width: 160px !important;
		height: 90px !important;
		background: url(../../../assets/img/my/sucess.png);
		background-size: 100% 100%;
		margin-top: 24px;
	}

	.p-j-div1-backimga {
		width: 160px !important;
		height: 90px !important;
		margin-top: 24px;
		background-size: 100%;
		background-image: url(../../../assets/img/my/fail.png);
	}

	.p-j-div2 {
		width: 288px;
		height: 100%;
		overflow: hidden;
		text-align: center;
	}

	.p-j-div3 {
		width: 288px;
		height: 100%;
		text-align: center;
		margin-left: 30px;
	}

	.p-j-div4 {
		width: 200px;
		height: 100%;
		text-align: center;
		align-items: center;
		margin-left: 30px;
	}

	.p-j-div4 div {
		height: 24px;
		width: auto;
		min-width: 64px !important;
		max-width: 80px !important;
		position: relative;
		top: 60px;
		border-radius: 5px;
		color: #FFFFFF;
		text-align: center;
		line-height: 24px;
		font-size: 12px !important;
		margin: 0 auto;
		cursor: pointer;
	}

	/* 我的订单样式 */
	.p-order-head {
		width: 100%;
		height: 46px;
		line-height: 46px;
		background: #FFFFFF;
		border-bottom: 1px solid #E6E6E6;
		display: flex;
		flex-wrap: wrap;
		font-size: 14px;
		color: #333333;
		align-items: flex-start;
	}

	.p-order-item {
		width: 100%;
		height: auto;
		background: #FFFFFF;
		margin-bottom: 20px;
	}

	.p-order-item-head {
		height: 55px;
		line-height: 55px;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
	}

	.p-order-item-head img {
		width: 14px;
		height: 15px;
		margin-top: auto;
		margin-bottom: auto;
		margin-left: 20px;
		margin-right: 6px;
	}

	.p-order-item-head div {
		margin-right: 16px;
	}

	.p-order-item-bottom {
		text-align: center;
		margin-top: 38px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		line-height: 36px;

	}

	.p-order-item-item {
		height: 137px;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.p-order-item-item img {
		height: 100px;
		width: 100px;
		border-radius: 10px;
		margin-left: 20px;
	}

	.p-o-div1 {
		margin-left: 20px;
		width: 520px;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.p-o-div1 div {
		margin-left: 20px;
		width: 280px;
		height: 100%;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		margin-top: 13px;
		line-height: 24px;
	}

	.p-o-div2 {
		width: 188px;
		text-align: center;
		font-size: 14px;
	}

	.p-o-div3 {
		width: 188px;
		text-align: center;
		font-size: 14px;
	}

	.p-o-div4 {
		width: 150px;
		text-align: center;
		font-size: 14px;
	}

	.p-o-div5 {
		width: 130px;
		text-align: center;
	}

	.p-o-div5 div {
		width: auto;
		min-width: 64px !important;
		max-width: 80px !important;
		height: 24px;
		background: #00D0B0;
		border-radius: 5px;
		font-size: 12px;
		text-align: center;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 24px;
		margin-left: auto;
		margin-right: auto;
		cursor: pointer;
	}

	.mr17px {
		margin-top: 17px;
	}

	.p-my-save {
		line-height: 54px;
		text-align: center;
		font-size: 20px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		width: 146px;
		margin-left: 131px;
		height: 54px;
		background: #00D0B0;
		cursor: pointer;
	}

	.p-order-item-bottom span:hover {
		color: #00D0B0;
	}

	.p-my-rank {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		position: absolute;
		cursor: pointer;
		right: 20px;
	}

	.p-my-rank img {
		width: 14px;
		height: 13px;
		margin-top: auto;
		margin-bottom: auto;
		margin-right: 8px;
	}

	.p-rank-i-img {
		width: 10px !important;
		height: 6px !important;
		margin-left: 10px;
	}

	.p-m-rank-posi {
		position: absolute;
		margin-top: 50px;
		z-index: 300;
	}

	.p-m-rank-item {
		width: 167px;
		height: 42px;
		background: #FFFFFF;
		line-height: 42px;
		text-align: center;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
		box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
	}

	.p-m-rank-item-c {
		background: #D9F8F3 !important;
		border: none !important;
		color: #00D0B0 !important;
	}

	.el-upload--text {
		background-color: #fff;
		border-radius: 6px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		border: 1px dashed #00D0B0 !important;
		width: 150px !important;
		height: 150px !important;
		border-radius: 8px !important;
		text-align: center;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.p-juan-title {
		font-size: 28px;
		margin-left: 60px;
		position: absolute;
		margin-top: 10px;
		color: white
	}

	.p-juan-about {
		font-size: 10px !important;
		position: absolute;
		margin-top: 2px;
		width: 100px;
		margin-top: 58px;
		margin-left: 45PX;
		color: white;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	svg:not(:root){
		height: 90px !important;
		width: 90px !important;
	}
	.el-progress-circle{
		height: 90px !important;
		width: 90px !important;
	}
	.el-progress__text{
		font-size: 14px !important;
		position: absolute;
		width: 100% !important;
		margin-left: 30px;
	}
</style>
